<template>
    <div>
        <header-portrate />

    <div class="pa-2 m-2">
        <bread-crumbs :items="items"></bread-crumbs>
        <tabs-comp :tabs="tabs" />
        <v-row :style="`direction:`+lang.dir+`;margin-left: 30px;margin-right:30px;`">
            <v-col cols="12" md="12" sm="12" :class="`text-`+lang.lalgin">
                <b-button
                    v-b-toggle.add_expense
                    class="btn-sm btn "
                    style="margin-top:11px;width:150px;background:transparent;border:none"
                    id="multiply-button" 
                    >
                    <div style="width:150px;" :class="`multiply-button-content-`+lang.lalgin">{{lang.add_expenses}}</div>
                </b-button>
            </v-col>
        </v-row>
        <v-row :style="`direction:`+lang.dir+`;margin-left: 30px;margin-right:30px;`">
            <v-data-table
                :headers="headers"
                :search="search"
                :items="exprows"
                class="elevation-1"
            >
                <template v-slot:item.action="{ item }">
                     <v-btn class="ma-1" style="width:70px;height:25px;background:red;color:#FFF" 
                        @click="DeleteExpense( item.id )">{{lang.delete}}</v-btn>
                     <v-btn class="ma-1" style="width:70px;height:25px;background:green;color:#FFF" 
                        @click="payit( item )" v-b-toggle.pay_expenses>{{lang.payit}}</v-btn>
                </template>

            </v-data-table>
            
        </v-row>
        <addExpense />
        <PayExpenses ref="payExp" />
    </div>

        <Footer />
    </div>
</template>
<script>
import axios from 'axios';
import addExpense from '../components/addExpense.vue'
import BreadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import PayExpenses from '@/components/pay-expenses.vue';
import Footer from '@/components/Footer.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';

export default{
    components: {
      addExpense,
        BreadCrumbs,
        TabsComp,
        PayExpenses,
        Footer,
        HeaderPortrate
    },
    data() {
        return {
            active_tab:4,
            search: '',
           
            
            exprows:[
               
            ],
            exps: {
                yearly: 0,
                half_year: 0,
                quarter: 0,
                monthly: 0,
                daily: 0,
            },
            currentExp: [],
        }
    },
    methods: {
        payit(item){
            this.currentExp = item;
            this.$refs.payExp.getCurretnExp();
        },
        printMe(item){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('export','inrecepit');
            post.append('type',1);
            post.append('id',item.id);
        },
        ExportIt(type){
            let url = this.$store.state.SAMCOTEC.e_path+'?';
            if(type != 'e'){
                let url = this.$store.state.SAMCOTEC.pd_path+'?inrecepit';
            }
            const post = new FormData();
            
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('export','inrecepit');
            post.append('type',1);
            post.append('sdate','');
            post.append('edate','');
            axios.post(
                url, post
            ).then((res) => {
                var rest = res.data;
                window.open('../api' + rest.url,'_blank')
            })
        },
        DeleteExpense(id){
            const post = new FormData();
            post.append("type" , "delExpense");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[id]",id);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    ///this.exprows = res.results.data.results;
                    
                    // // console.log(res);
                    this.getExpenses();
                }
            )
        },
        getExpenses(){
            const post = new FormData();
            post.append("type" , "getExpense");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[all]","all");
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    this.exprows = res.results.data.results;
                }
            )
        }
    },
    created() {
        this.getExpenses();
        // setInterval(() => {
        //     this.getExpenses();
        // }, 10000);
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        tabs: function(){
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        },
        items: function() {
            return {
                    text: this.lang.recurring_expensess,
                    disabled: true,
                    to: '/finance/expenses',
                }
        },
        headers:function() {
            return [
                {
                    text: '',
                    align: 'center',
                    sortable: false,
                    value: 'id',
                },
                {
                    text: this.lang.expense_type,
                    align: 'center',
                    sortable: false,
                    value: 'expens_type',
                },
                {
                    text: this.lang.yearly,
                    align: 'center',
                    sortable: false,
                    value: 'yearly',
                },
                {
                    text: this.lang.midterm,
                    align: 'center',
                    sortable: false,
                    value: 'half_year',
                },
                {
                    text: this.lang.quarterly,
                    align: 'center',
                    sortable: false,
                    value: 'quarter',
                },
                {
                    text: this.lang.monthly,
                    align: 'center',
                    sortable: false,
                    value: 'monthly',
                },
                {
                    text: this.lang.daily,
                    align: 'center',
                    sortable: false,
                    value: 'daily',
                },
                {
                    text: this.lang.action,
                    align: 'center',
                    sortable: false,
                    value: 'action',
                    class: 'actionWidth',
                },
            ]
        }
    },
}
</script>
<style>
.actionWidth{
    width: 100px !important;
}
</style>